.fade-enter-active {
    transition: opacity .5s;
}
.fade-leave-active {
    transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.cursor-busy {
    cursor: wait;
}