@import url("publications.css");
@import url("layouts.css");
@import url("transitions.css");
@import url("bootstrap.css");
@import url("resizer.css");
@import url("https://www.optimalegal.online/stylesheets/OptimaLegal.css");

html,
body {
  height: 100%;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 0; /* Required for Firefox/IoS bug handing flex scrollbars */
}

.click-canvas {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 99;
}

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.document-content {
  font-family: "Segoe UI", Helvetica, Verdana, Arial, ArialMT !important;
  font-size: 11pt !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

footer {
  background-color: #e2e2e2;
  border-top: solid 1px lightgray;
  color: #7a7a7a;
  font-size: 0.8em;
}

details {
  cursor: pointer;
  font-size: 75%;
  color: #5f5f5f;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

.publication-list figure {
  display: inline-block;
  margin: 2px 4px;
}

.publication-list figcaption {
  text-align: center;
}

.tree-view-container {
  font-family: "Segoe UI", Helvetica, Verdana, Arial, ArialMT !important;
  font-size: 11pt !important;
  overflow-x: hidden;
}
.tree-view {
  margin: 0 !important;
  padding: 0.25rem !important;
}
.tree-view ul {
  margin-left: 1.5em;
  margin-top: 0;
  padding: 0;
}
.tree-view li {
  list-style: none;
  margin: 0;
  cursor: default;
}
.tree-view a {
  color: #5f5f5f;
  text-indent: -2em;
}
.tree-view a.selected {
  color: #4d4d4d;
  font-weight: bold;
}
.tree-view .toggler {
  cursor: pointer;
  text-align: center;
  width: 1.25em;
  color: gray;
  display: inline-block;
}
.tree-view .toggler:hover {
  color: black;
}

span.fa,
fa {
  line-height: inherit;
}

.card-wrapper {
  padding: 0;
  margin: 0;
}

.carousel {
  display: table;
}
.carousel .figure {
  margin: 0;
  padding: 4px;
  border-radius: 3px;
}
.carousel .figure:hover {
  color: black;
  transform: scale(1.02);
}
.carousel .figure:hover .figure-caption {
  color: black;
  font-weight: bold;
}
.carousel img {
  display: table-cell;
}
.carousel a {
  display: table;
}
.carousel a:hover {
  text-decoration: none;
}
.carousel .figure-caption {
  display: table-caption;
  caption-side: bottom;
  width: 100%;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.buttonLink:disabled {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: lightgray;
  text-decoration: underline;
  cursor: default;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Sidemenu styles. */
.sidemenu {
  width: 600px;
  min-width: 600px;
  max-width: 600px;
  left: 0;
  right: auto;
  z-index: 999999;
  position: fixed;
  top: -1px;
  bottom: -1px;
  padding: 0px;
  width: auto;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  transition: 400ms;
  word-wrap: break-word;
}
.sidemenu-open {
  left: 0;
}
.sidemenu-closed {
  left: -595px;
}
.sidemenu .row, .sidemenu .col-12 {
  padding: 0;
}
.sidemenu > .sidemenu-content {
  margin: 0;
  overflow: hidden;
}
.sidemenu-content {
  height: 100%;
  overflow-y: scroll;
}
.sidemenu-handle {
  opacity: 0.75;
  color: gray;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  width: 28px;
  height: 48px;
  position: absolute;
  top: 45%;
  cursor: pointer;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #fff;
  -webkit-box-shadow: 1px 0px 8px rgba(0, 0, 0, .175);
  box-shadow: 1px 0px 8px rgba(0, 0, 0, .175);
  right: -28px;
}
.sidemenu-handle:hover {
  color:black;
}
.sidemenu .toggle-icon {
  display: block;
  line-height: 48px;
  text-align: center;
  background-color: white;
  opacity: .85;
  filter: opacity(.85);
}

/* Large tablet styles. */
@media only screen and (max-device-width: 600px) {
  .sidemenu {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    left: 0;
    right: auto;
    z-index: 999999;
    position: fixed;
    top: -1px;
    bottom: -1px;
    padding: 0px;
    width: auto;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    transition: 400ms;
    word-wrap: break-word;
  }
  .sidemenu-closed {
    left: -395px;
  }
}

/* Medium tablet styles. */
@media only screen and (max-device-width: 500px) {
  .sidemenu {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    left: 0;
    right: auto;
    z-index: 999999;
    position: fixed;
    top: -1px;
    bottom: -1px;
    padding: 0px;
    width: auto;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    transition: 400ms;
    word-wrap: break-word;
  }
  .sidemenu-closed {
    left: -345px;
  }
}

/* Phone styles. */
@media only screen and (max-device-width: 400px) {
  .sidemenu {
    width: 335px;
    min-width: 335px;
    max-width: 335px;
    left: 0;
    right: auto;
    z-index: 999999;
    position: fixed;
    top: -1px;
    bottom: -1px;
    padding: 0px;
    width: auto;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    transition: 400ms;
    word-wrap: break-word;
  }
  .sidemenu-closed {
    left: -330px;
  }
}
