

/*---------------------*/
/* Bootstrap Overrides */
/*---------------------*/

.card .card-body-flush {
    padding: 0;
}

/* Reset change(s) from OptimaIndex.css */
.navbar-nav li, .list-inline .list-inline-item {
    margin-bottom: inherit;
}