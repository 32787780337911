.publication-header {
    min-height: 8em;
}

.publication-header p {
    color: #666666;
}

.publication-section header {
    font-size: 125%;
}
.publication-section {
    background-color: #fdfdfd;
}

.annotation-icon {
    display: block;
    margin-left: .5em;
    margin-bottom: -.1em;
    width: 1em;
    height: 1em;
    background: url(../assets/annotation.svg);
    background-size: 1em 1em;
}
.annotation-icon:hover {
    cursor: pointer;
}
.annotation-icon-off {
    background: url(../assets/annotation-off.svg);
}

.search-result-item {
    border-radius: 5px;
    cursor: pointer;
}

.search-result-item:hover {
    background-color: #efefef;
}

.search-result-item img {
    max-width: 44px;
    display: inline-block;
}

.search-result-item ul {
    display: inline-block;
}

.selected {
    background-color: lightgray;
}
