.flex-me {
    flex: 1;
    min-height: 0;              /* Required for Firefox/IoS bug handing flex scrollbars */
}
.flex-down {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;    
    flex-direction: column;
    min-height: 0;
    height: 100%;
}
.flex-across {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
    min-height: 0;
}
.full-height {
    height: 100%;
    position: relative;
}
.full-width {
    width: 100%;
}

.v-scroll {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Momentum based scrolling on ioS */
}

.v-scroll > div {
    flex-wrap: nowrap;
    min-height: 0;
}

.splitpanes.default-theme .splitpanes__splitter {
    background-color: rgba(0,0,0,0.09);
}

.nowrap {
    flex-wrap: nowrap !important;
    overflow-wrap: normal !important;
    white-space: nowrap !important;
}